import React, { Component } from "react";
import ReactExport from "react-export-excel";
import { map, isEmpty } from "lodash"
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import CurrencyFormat from "react-currency-format";
import LoadingSpinner from "../components/components/LoadingSpinner";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Report_wawancara_detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data_student: "",
      is_show: false,
      data: "",
      totalUpp: 0,
      totalSpp: 0,
      rataUpp: 0,
      titlePayment: '',
      reportData: [],
      listMaster: {},
      listNonRutin: [],
      listRutin: [],
      rataSpp: 0,
      isLoading: true,
    };
  }

  handleClick = (param) => (e) => {
    e.preventDefault();
    // localStorage.setItem("student_id", param);
    const findIdx = this.state.reportData.findIndex((i) => i.registration_id === param.trim());
    var dataStudent = this.state.reportData[findIdx]
    var dataJalur = dataStudent.registration_id + ' - ' + dataStudent.fullname;
    localStorage.setItem("DATA_JALUR", dataJalur);
    localStorage.setItem("STUDENT_DETAIL", JSON.stringify(dataStudent));
    window.open("/detailstudent", "_blank");
  };

  getMaster(id = '', table) {
    var array = this.state.listMaster[table];
    var arrayfilter = array.filter((data) => { if (data.id === parseInt(id)) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getMasterBeforeState(id = 0, table) {
    var array = JSON.parse(localStorage.getItem('DATA_PARAMETER'))[table];
    var arrayfilter = array.filter((data) => { if (data.id == id) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getTitlePayment() {
    var array = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prm_payment_interview;
    var arrayfilter = array.filter((data) => { if (data.is_routine === 0) return data });
    var name = arrayfilter.length > 0 ? arrayfilter[0].name : '';
    return name;
  }

  getTotalUpp(array = []) {
    let total = 0;
    if (array.length > 0) {
      map(array, (data) => {
        if (data.subdomain === global.variable.subdomain) {
          if (data.is_routine === '0' || data.is_routine === 0) {
            data.nominal = data.nominal ? parseFloat(data.nominal) : parseFloat(data.value);
            //hitung jumlah potongan
            if (data.discount > 0 && data.discount !== null) {
              data.potongan = parseFloat(data.discount);
            } else {
              data.potongan = 0;
            }

            //hitung total bayar
            data.ttl = parseFloat(data.nominal) - parseFloat(data.potongan);
            if (data.is_dp == 1) {
              data.dp = data.dp ? data.dp : 0;
              data.ttl_bayar = parseFloat(data.ttl) - parseFloat(data.dp);
            } else {
              data.ttl_bayar = parseFloat(data.ttl)
            }
            total = data.ttl_bayar;
          }
        }
      })
    }
    return total;
  }

  getTotalSpp(array = []) {
    let total = 0;
    if (array.length > 0) {
      map(array, (data) => {
        if (data.subdomain === global.variable.subdomain) {
          if (data.is_routine === 1 || data.is_routine === '1') {
            data.nominal = data.nominal ? parseFloat(data.nominal) : parseFloat(data.value);
            total += data.nominal
          }
        }
      })
    }
    return total;
  }

  getNominalNonRutin(listInterview, idNonRutin) {
    let value = 0;
    let dataInterview = listInterview.filter((data) => { if (data.id === idNonRutin) return data });
    let discount = 0;
    if (dataInterview.length > 0) {
      let interview = dataInterview[0];
      let nominal = interview.nominal ? parseInt(interview.nominal) : 0;
      if (interview.discount !== 0) {
        discount = (nominal * interview.discount) / 100;
      }
      value = nominal - discount;
    }
    return value;
  }

  getTotalNonRutin(idNonRutin) {
    let total = 0;
    let listReport = this.state.reportData;
    listReport.map((data) => {
      total += this.getNominalNonRutin(data.interview_result, idNonRutin);
    })
    return total;
  }

  getRataNonRutin(idNonRutin) {
    let rata = 0;
    let total = this.getTotalNonRutin(idNonRutin);
    rata = total / this.state.reportData.length;
    return rata.toFixed(0);
  }

  async componentDidMount() {
    let data_report;
    var links = JSON.parse(localStorage.getItem('report_wawancara'));
    this.setState({
      isLoading: true,
    })
    await fetch(global.variable.LINK_API + 'report' + links.trim(),
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          data_report = resData.data;
        } else {
          global.swal("Error", resData.error, "info")
        }

      });

    let data = [];
    let total1 = 0;
    let total2 = 0;
    let rata = 0;
    let rataSpp = 0;
    let Data2 = [];
    let dataParam = JSON.parse(localStorage.getItem("DATA_PARAMETER"))
    let paramNonRutin = dataParam.prm_payment_interview.filter((data) => { if (parseInt(data.is_routine) === 0) return data });
    let paramRutin = dataParam.prm_payment_interview.filter((data) => { if (parseInt(data.is_routine) === 1) return data });

    data_report.map((datax) => {
      let items3 = Object.create({});
      const total = this.getTotalUpp(datax.interview_result);
      const totalNonRutin = this.getTotalSpp(datax.interview_result);
      let noteReject = '';
      datax.totalPaymentNotRoutine = total;
      datax.totalSPP = totalNonRutin;
      items3[`Nama Lengkap`] = datax.fullname;
      items3[`Nomor Pendaftaran`] = datax.year + "-" + datax.registration_number;
      items3[`Nomor Handphone`] = datax.hp;
      items3[`Status`] = this.getMasterBeforeState(datax.interview_status_id, 'pdf_interview_status');
      items3[`Pewawancara`] = datax.interview_by ? datax.interview_user !== null ? datax.interview_user.name : "" : "";
      paramNonRutin.map((data) => {
        items3[data.name] = this.getNominalNonRutin(datax.interview_result, data.id);
      })
      items3['SPP'] = totalNonRutin;
      items3[`Catatan Tentang Pendaftar`] = datax.note_verification ? datax.note_verification : "";
      if (parseInt(datax.interview_status_id) === 3) {
        noteReject = datax.note_reject ? datax.note_reject : "";
      }
      items3[`Catatan Wawancara`] = noteReject;
      total1 += parseFloat(total);
      total2 += parseFloat(totalNonRutin);
      Data2.push(items3);
      return { ...datax };
    })

    rata = parseFloat(total1) / parseInt(data_report.length)
    rataSpp = parseFloat(total2) / parseInt(data_report.length)

    let items = Object.create({});
    items.fullname = "TOTAL";
    items.upp =
      "Rp" + total1.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    let items2 = Object.create({});
    items2.fullname = "Rata-Rata";
    items2.upp =
      "Rp" + rata.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    data.push(items2);

    this.setState(
      {
        report: Data2,
        totalUpp: total1,
        totalSpp: total2,
        rataUpp: rata,
        rataSpp: rataSpp,
        is_show: true,
        reportData: data_report,
        listMaster: JSON.parse(localStorage.getItem("DATA_PARAMETER")),
        titlePayment: this.getTitlePayment(),
        listNonRutin: paramNonRutin,
        listRutin: paramRutin,
        isLoading: false,
      },
    );
  }

  createExcelSheet(data) {
    let final = [];
    for (var key in data[0]) {
      final.push(<ExcelColumn label={key} value={key} key={key} />);
    }
    return final;
  }

  render() {
    let counter = 0;
    let paramNonRutin = this.state.listNonRutin.sort((a, b) => { return a.id - b.id });
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <MDBCard className="mb-5">
                    <MDBCardBody>
                      <MDBBreadcrumb color="cyan lighten-4">
                        <MDBBreadcrumbItem active>
                          <a href="/home">Home</a>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>
                          Laporan Wawancara
                        </MDBBreadcrumbItem>
                      </MDBBreadcrumb>
                      {this.state.isLoading ? <LoadingSpinner /> : (
                        <ExcelFile
                          element={
                            <MDBBtn color="primary" block style={{ margin: 5, height: 50 }} >
                              Unduh Laporan wawancara
                            </MDBBtn>
                          }
                        >
                          <ExcelSheet data={this.state.report} name="Report Wawancara">
                            {this.state.is_show ? this.createExcelSheet(this.state.report) : null}
                          </ExcelSheet>
                        </ExcelFile>
                      )}
                      <br></br>
                      <MDBTable>
                        <thead className="blue-grey lighten-4">
                          <tr>
                            <th>#</th>
                            <th width="150">Nama Lengkap (Nama dapat diklik)</th>
                            <th width="100">Nomor Pendaftaran</th>
                            <th>Nomor Handphone</th>
                            <th>Status</th>
                            <th>Pewawancara</th>
                            {
                              paramNonRutin.map((item, idx) => {
                                return <th>{item.name}</th>
                              })
                            }
                            <th>SPP</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.reportData.map((item, idx) => {
                            counter++;
                            return (
                              <tr key={idx}>
                                <td>{counter}</td>
                                <td
                                  style={{ color: "blue" }}
                                  onClick={this.handleClick(item.registration_id)}
                                >
                                  {item.fullname.toUpperCase()}
                                </td>
                                <td>
                                  {item.year +
                                    "-" +
                                    item.registration_number}
                                </td>
                                <td>{item.hp}</td>

                                <td>
                                  {this.getMaster(item.interview_status_id, 'pdf_interview_status')}
                                </td>
                                <td>
                                  {item.interview_name
                                    ? item.interview_name
                                    : "Panitia PPDB"}
                                </td>
                                {
                                  paramNonRutin.map((dataNonRutin, idx) => {
                                    return (
                                      <td>
                                        <CurrencyFormat
                                          input="number"
                                          prefix={"Rp. "}
                                          readOnly
                                          value={this.getNominalNonRutin(item.interview_result, dataNonRutin.id)}
                                          maxLength="25"
                                          thousandSeparator={true}
                                          style={{ width: "100%" }}
                                          displayType={"text"}
                                        />
                                      </td>
                                    )
                                  })
                                }
                                <td>
                                  {item.totalSPP > 0 ? (
                                    <CurrencyFormat
                                      input="number"
                                      prefix={"Rp. "}
                                      readOnly
                                      value={item.totalSPP > 0 ? item.totalSPP : '0'}
                                      maxLength="25"
                                      thousandSeparator={true}
                                      style={{ width: "100%" }}
                                      displayType={"text"}
                                    />
                                  ) : 0}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={6}>TOTAL </td>
                            {
                              paramNonRutin.map((dataNonRutin, idx) => {
                                return (
                                  <td>
                                    <CurrencyFormat
                                      input="number"
                                      prefix={"Rp. "}
                                      readOnly
                                      value={this.getTotalNonRutin(dataNonRutin.id)}
                                      maxLength="25"
                                      thousandSeparator={true}
                                      style={{ width: "100%" }}
                                      displayType={"text"}
                                    />
                                  </td>
                                )
                              })
                            }
                            <td>
                              <CurrencyFormat
                                input="number"
                                prefix={"Rp. "}
                                readOnly
                                value={this.state.totalSpp}
                                maxLength="25"
                                thousandSeparator={true}
                                style={{ width: "100%" }}
                                displayType={"text"}
                              />
                              &nbsp;
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={6}>RATA-RATA</td>
                            {
                              paramNonRutin.map((dataNonRutin, idx) => {
                                return (
                                  <td>
                                    <CurrencyFormat
                                      input="number"
                                      prefix={"Rp. "}
                                      readOnly
                                      value={this.getRataNonRutin(dataNonRutin.id)}
                                      maxLength="25"
                                      thousandSeparator={true}
                                      style={{ width: "100%" }}
                                      displayType={"text"}
                                    />
                                  </td>
                                )
                              })
                            }
                            <td>
                              <CurrencyFormat
                                input="number"
                                prefix={"Rp. "}
                                readOnly
                                value={this.state.rataSpp.toFixed(0)}
                                maxLength="25"
                                thousandSeparator={true}
                                style={{ width: "100%" }}
                                displayType={"text"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </MDBTable>
                      {/* </div>
                      </div> */}
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Report_wawancara_detail;
